if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.Item = function(Category,SubCategory,PartType,Brand,PartNumber,unitprice,qty,Vehicle){
    if(!(this instanceof ActivityTracker.Item))
        throw new Error("Uncaught TypeError: ActivityTracker.Item is a construtor.");
    if(!(Vehicle instanceof ActivityTracker.Vehicle))
        throw new Error("Uncaught TypeError: The parameter Vehicle is not an instance of ActivityTracker.Vehicle");

    var _affiliation,
        _place_id;
    this.getCategory = function(){
        return Category;
    }
    this.getSubCategory = function(){
        return SubCategory;
    }
    this.getPartType = function(){
        return PartType;
    }
    this.getBrand = function(){
        return Brand;
    }
    this.getPartNumber = function(){
        return PartNumber;
    }
    this.getCurrency = function(){
        return 'CAD';
    }
    this.getPrice = function(){
        return parseFloat(unitprice);
    }
    this.getQuantity = function(){
        return parseInt(qty);
    }
    this.getVehicle = function(){
        return Vehicle;
    }
    this.getAffiliation = function(){
        return _affiliation;
    }
    this.setAffiliation = function(affiliation){
        _affiliation = affiliation;
    }
    this.getPlaceID = function(){
        return _place_id;
    }
    this.setPlaceID = function(place_id){
        _place_id = place_id;
    }
    this.getTotal = function(){
        return this.getPrice() * this.getQuantity()
    }
};
