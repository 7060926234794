if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.SenderGoogleTag = function(){

    function sendRequest(obj,evtName){
        try{
            if (typeof dataLayer != undefined && typeof dataLayer === 'function'){
                evt = {ecommerce:{}};
                if(evtName != undefined) evt.event = evtName
                evt.ecommerce = obj;
                dataLayer.push(evt);
                dataLayer.push({ ecommerce: null });  
            }
        }
        catch(Exception){
            console.warn(Exception)
        }
    }

    this.push = function(eventName){
        try{
            dataLayer.push({ event: eventName });  
        }
        catch(Exception){
            console.warn(Exception)
        }
    }

    this.viewProducts = function(items){
        var data = {
            items: ActivityTrackerItemsColectionToGoogleItemList(items)
        }
        sendRequest(data,'view_item_list');
    }

    this.viewProduct = function(item){
        //var data = ActivityTrackerItemsColectionToGoogleCheckoutModel(items);
        //if(data) sendRequest({items:data.items},'view_item');
    }

    this.addToCart = function(item){
        var data = ActivityTrackerItemColectionToGoogleCheckoutModel(item);
        if(data) sendRequest({items:data.items},'add_to_cart');
    }

    this.removeToCart = function(items){
        var data = ActivityTrackerItemsColectionToGoogleCheckoutModel(items);
        if(data) sendRequest({items:data.items},'remove_from_cart');
    }

    this.viewCart = function(items){
        var data = ActivityTrackerItemsColectionToGoogleCheckoutModel(items);
        if(data) sendRequest(data,'view_cart');
    }

    this.beginCheckout = function(items){
        var data = ActivityTrackerItemsColectionToGoogleCheckoutModel(items);
        if(data) sendRequest(data,'begin_checkout');
    }

    this.addPaymentInfo = function(items){
        var data = ActivityTrackerItemsColectionToGoogleCheckoutModel(items);
        if(data) sendRequest(data,'add_shipping_info');
    }

    this.purchase = function(transaction){
        var data = ActivityTrackerItemsColectionToGoogleCheckoutModel(transaction.getItems());
        if(data) sendRequest({
            transaction_id: transaction.getId(),                         // Transaction ID. Required for purchases and refunds.
            affiliation: transaction.getAffiliation(),
            value: transaction.getTotal(),                     // Total transaction value (incl. tax and shipping)
            currency: transaction.getCurrency(),
            tax: transaction.getTaxes(),
            shipping: transaction.getShippingFee(),
            revenue: transaction.getSubTotal(),
            //coupon: 'SUMMER_SALE'
            items:data.items},'purchase');
    }

    function ActivityTrackerItemsColectionToGoogleItemList(items){
        var rsp = [];
        items.forEach(function(item){
            rsp.push(ActivityTrackerItemToGoogleItem(item))
        });
        return rsp
    }

    function ActivityTrackerItemToGoogleItem(item){
        var rsp = {
            item_list_name:item.getVehicle().getName(),
            item_id : item.getPartNumber(),
            item_name : item.getPartNumber(),
            item_brand: item.getBrand(),
            /*item_category : item.getCategory(),
            item_category2: item.getSubCategory(),
            item_category3: item.getPartType(),*/
            item_category : item.getPartType(),
        }

        if(item.getVehicle().getMake()) rsp.marque = item.getVehicle().getMake();
        if(item.getVehicle().getModel()) rsp.modele = item.getVehicle().getModel();
        if(item.getVehicle().getYear()) rsp.annee = item.getVehicle().getYear();
        
        if(item.getPrice()){
            rsp.currency = item.getCurrency();
            rsp.price = item.getPrice();
        }
        if(item.getQuantity()) rsp.quantity = item.getQuantity();
        if(item.getAffiliation())  rsp.affiliation = item.getAffiliation();
        if(item.getPlaceID())  rsp.location_id = item.getPlaceID();
        
        return rsp;
    }

    function ActivityTrackerItemsColectionToGoogleCheckoutModel(items){
        return {
            currency: items.firstOrDefault().getCurrency(),
            value: items.Sum('total'),
            items: ActivityTrackerItemsColectionToGoogleItemList(items)
        }
    }

    function ActivityTrackerItemColectionToGoogleCheckoutModel(item){
        return {
            currency: item.getCurrency(),
            value: item.getTotal(),
            items: ActivityTrackerItemToGoogleItem(item)
        }
    }
};