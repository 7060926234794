if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.SenderFacebook = function(){

    function pushFBPixel(evtName, obj){
        if (typeof fbq != undefined && typeof fbq === 'function') fbq('track',evtName,obj);
    }

    this.search = function(search ){
        pushFBPixel('Search', {
            search_string: search
        })
    }

    this.viewProducts = function(items){
        items.forEach(function(item){
            this.viewProduct(item)
        }.bind(this))
    }

    this.viewProduct = function(item){
        var data = {
            currency: 'CAD', 
            content_ids: [item.getPartNumber()],
            content_category: item.getVehicle().getName(),
            content_name: item.getBrand()+ " " + item.getPartType(),
            content_type: 'product',
            value: item.getPrice()
        }
        pushFBPixel('ViewContent', data);
    }

    this.initiateCheckout = function(items, subtotal){
        ids = [];
        items.forEach(function(item){
            ids.push(item.id);
        });
        pushFBPixel('InitiateCheckout', {
            currency: 'CAD', 
            content_ids: ids,
            contents: items,
            num_items: items.length,
            value: subtotal,
        });
    }

    this.addPaymentInfo = function(items,subtotal){
        console.warn("Not Implemented")
    }

    this.viewCart = function(items){
        console.log("fbq doesn't support event cart view")
    }

    this.purchase = function(transaction){
        var ids = [];
        var contents = [];
        transaction.getItems().forEach(function(item){
            ids.push(item.getPartNumber());
            contents.push({ 
                'id': item.getPartNumber(), 
                'quantity': 2, 
                'name': item.getBrand() + ' ' + item.getPartType(),
                'category': item.getVehicle().getName(),
            });
        });

        pushFBPixel('Purchase', {
            currency: 'CAD', 
            content_ids: ids,
            content_name: 'Confirmation',
            contents: contents,
            num_items: transaction.getItems().Count(),
            value: transaction.getTotal(),
            content_type: 'product'
        });
    }

    this.addToCart = function(item){
        pushFBPixel('AddToCart', {
            value: item.getPrice(), 
            currency: 'CAD', 
            content_ids: [item.getPartNumber()],
            content_category: item.getVehicle().getName(),
            content_name: item.getBrand() + ' ' + item.getPartType(),
            contents: [
                {
                    id: item.getPartNumber(),
                    quantity: item.getQuantity()
                }
            ],
            content_type: 'product',
        });
    }
};