/*var Conatact_Map;

Conatact_Map = new function(){
    var map = new google.maps.Map(document.getElementById('gmap'),{

    });
}*/
Math.roundHalf = function(n){
    return Math.round(n*2)/2;
}
Number.prototype.toStars = function(t){
    var n = Math.roundHalf(this),
        t = isNaN(t) ? 5 : t,
        h = '',
        s = '&nbsp;';
    for(var ж = 1; ж<=t; ж++){
      if(h != '')       h += s;
      if(n >=ж)         h += '<i class="fa fa-star" aria-hidden="true"></i>';
      else if(n > ж-1)  h += '<i class="fa fa-star-half-o" aria-hidden="true"></i>';
      else              h += '<i class="fa fa-star-o" aria-hidden="true"></i>';
    }
    return h;
  };


function Template_Map(){
    if(!(this instanceof Template_Map))
        throw new Error("Uncaught TypeError: Template_Map is a construtor.");
    
    var place_id = 'ChIJRRy8iEc-K4gRh92glHA5pI0';

    function RoutesBox(place){
        // Set CSS for the control border.
        var controlUI = document.createElement('div');
        var it = "https://www.google.com/maps/dir/?api=1&destination="+encodeURIComponent(place.formatted_address);
        controlUI.style.backgroundColor = '#fff';
        controlUI.style.border = '2px solid #fff';
        controlUI.style.borderRadius = '3px';
        controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlUI.style.margin = '10px';
        controlUI.style.padding = '10px';
        controlUI.style.textAlign = 'left';
        controlUI.innerHTML = '<div class="place-desc-large" style="width: 120px;display: inline-block;"><div style="font-weight:bold; font-size:16px;" jstcache="36" class="place-name" jsan="7.place-name">'+place.name+'</div> <div style="margin-top: 6px;" jstcache="37" class="address" jsan="7.address">'+place.formatted_address+'</div> </div>'
        controlUI.innerHTML +='<div jstcache="38" class="navigate" style="display: inline-block;vertical-align: top;height: 43px;padding: 0 7px;" > <div jsaction="placeCard.directions" class="navigate"> <a style="color:#4b96f3" target="_blank" jstcache="52" href="'+it+'" class="navigate-link"> <div class="icon navigate-icon" style="background-image: url(https://maps.gstatic.com/mapfiles/embed/images/entity11.png);background-size: 70px 210px;width: 22px;height: 22px;overflow: hidden;margin: 0 auto;" ></div> <div jstcache="53" class="navigate-text"></div> </a> </div> <div class="tooltip-anchor"> <div class="tooltip-tip-outer"></div> <div class="tooltip-tip-inner"></div> </div> </div>';
        if(place.url)
        controlUI.innerHTML +='<div class="bottom-actions" style="padding-top:5px"> <div class="google-maps-link"> <a style="color:#4b96f3" target="_blank" jstcache="46" href="'+place.url+'" jsaction="mouseup:placeCard.largerMap">'+ __('View larger map')+'</a> </div>';
        controlUI.innerHTML +='</div>'
        return controlUI
    }

    function GetPlaceInfo(id,calback){
        var service = new google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({
            placeId: id
          }, function(place, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                calback(place);
            }
          });
    }

    var div = document.getElementById('gmap');
    if(div){
        var bounds = new google.maps.LatLngBounds();
        var myLatLng = {lat: 43.681777412335336, lng: -79.71691045045158};
        var map = new google.maps.Map(div,{
            center: myLatLng,
            zoom: 15,
            disableDefaultUI: true,
            zoomControl:true,
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT,
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              mapTypeIds: ['roadmap', 'satellite']
            }
        });
        map.setOptions({styles: getMapStyles()});
        var service = new google.maps.places.PlacesService(map);
        GetPlaceInfo(place_id,function(place){
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(RoutesBox(place));
            var marker = new google.maps.Marker({
                position: place.geometry.location,
                map: map,
                title: place.name,
            });
            map.fitBounds(bounds);
            map.setCenter(place.geometry.location);
        });

    }


    var review = document.querySelector('.js-google-reviews');
    
    if(review){
        GetPlaceInfo(place_id,function(place){
            review.querySelector('.js-rating').innerHTML = '<span>'+place.rating.toFixed(1)+'</span> ' + place.rating.toStars();
            review.querySelector('.js-reviews-count')._e("%s reviews",[place.user_ratings_total]);
            //console.log(place);
            //map.controls[google.maps.ControlPosition.TOP_LEFT].push(RoutesBox(place));
            slider = $(review).find('.js-banner--autoload')

            place.reviews.forEach(function(r){
                var date = new Date(r.time *1000);
                slider.slick('slickAdd','<li><div class="google-review">'+
                    '<div class="author"><img class="avatar" src="'+r.profile_photo_url+'" alt=""><div class="info"><div class="name">'+r.author_name+'</div>'+/*'<div class="more">Local Guide · 27 avis</div>'+*/'</div></div>'+
                    '<div class="my1"><span class="rating">'+r.rating.toStars()+'</span> <time pubdate datetime="'+date.toISOString()+'">'+r.relative_time_description+'</time></div>' +
                    '<blockquote class="my2">'+r.text+'</blockquote>'+
                    /**/

                    /* <a class="mr5"><i class="fa fa-thumbs-o-up"></i> Like</a>
                    <a><i class="fa fa-share"></i> Share</a>*/
                '</div></li>');
            });

            $(review).find('.slick-arrow.slick-prev').css("display","inline-block")
        });
    }
}

function getMapStyles(){
    var styler = '#009bf4'
    try{
        if(color_primary != '') styler = color_primary;
    }
    catch(ex){}
    return [
        {
             "stylers": [
                 {
                    "hue" : styler
                 }
             ]
         },
         {
             "featureType": "road",
             "elementType": "labels",
             "stylers": [
                 {
                     "visibility": "off"
                 }
             ]
         },
         {
             "featureType": "road",
             "elementType": "geometry",
             "stylers": [
                 {
                     "lightness": 100
                 },
                 {
                     "visibility": "simplified"
                 }
             ]
         }
     ];
}

function getLocation() {
    return new Promise(function(resolve,reject){
        try{
            navigator.geolocation.getCurrentPosition(resolve,reject);
        } 
        catch(ex){
            console.log(ex)
            reject();
        }
    });
}

function CreateClusterIcon(icon_url){
    var clusterStyles = [
        {
           textColor: 'white',
           url: icon_url, 
           height: 58,
           width: 50,
           textSize: 20,
           anchor:[8,0],
           backgroundPosition:'0px 0px'
         }
    ];

    return {
        gridSize: 50,
        styles: clusterStyles,
        maxZoom: 15
    };
}

function initMap(){
    var div = document.getElementById('gmap');
    var map = new google.maps.Map(div,{
        zoom: 15,
        zoomControl:true,
        mapTypeControl: false,
    });
    
    var bounds = new google.maps.LatLngBounds();
    window.markers = new MarkerClusterer(map, [],CreateClusterIcon('/dist/images/map-marker.svg'));
    $('[data-latitude]').each(function(){
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng($(this).data('latitude'),$(this).data('longitude')),
            map,
            height: 50,
            width: 50,
            title: $(this).find('.js-title').text(),
            icon: {
                url: '/dist/images/map-marker-hole.svg', 
                scaledSize: new google.maps.Size(50, 50), 
                origin: new google.maps.Point(0,0), 
                anchor: new google.maps.Point(25, 50) 
            },
            id: $(this).attr('id')
        });
        window.markers.addMarker(marker);
        bounds.extend(marker.getPosition());
        marker.addListener("click", function(t){;
            var element = $('#'+marker.id);
            $('[data-latitude]').removeClass('active');
            element.addClass('active');
            if(window.innerWidth < 760)
                $('html, body').animate({
                    scrollTop: element.offset().top-80
                }, 500);
            else{
                var scrollable = $('.pa-where-to-buy--nav')
                scrollable.animate({
                    scrollTop: scrollable.scrollTop() - scrollable.offset().top + $(element).offset().top 
                }, 500);
            }
        });
    });
    map.fitBounds(bounds);
    
    getLocation().then(function(position){
        var _mii = new google.maps.Marker({
            position: new google.maps.LatLng(position.coords.latitude,position.coords.longitude),
            map: map,
            icon: {
                url: '/dist/images/map-marker-mii.png',
                scaledSize: new google.maps.Size(50, 50), 
                origin: new google.maps.Point(0,0), 
                anchor: new google.maps.Point(25, 50) 
            },
            id: ''
        });
    },function(){});
    map.setOptions({styles: getMapStyles()});
}
