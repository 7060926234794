SmartBar = new function(){
    jQuery('document').ready(function(){
        var _container = $('<div class="smart-search-result"></div>');
        $('.js-smart-search').append(_container);
    });
    var _form = $('<div class="c-smart-search" style="display:none"><div class="c-smart-search--modal"><form autocomplete="off" class="input-search-bar js-search-ai"><input type="search" autocomplete="off" placeholder="Chercher par année, marque, modèle, produit, type de pièce ou manufacturier"  required><button class="search"><i class="fa fa-search"></i></button><button class="close"><i class="fa fa-close"></i></button></form></div></div>')
    var _xhr = null;
    var _timeout=null;

    _form.on('input propertychange','form.input-search-bar input[type=search]',function(){
        if(_xhr)_xhr.abort();
        var form = $(this).closest('form');
        form.find('button.search .fa').addClass('fa-pencil').removeClass('fa-search fa-circle-o-notch fa-spin');
        
        if(_timeout) clearTimeout(_timeout);
        _timeout = setTimeout(function(){
            form.submit();
        },5000)
    });

    _form.on('submit','form.input-search-bar',function(e){
        e.preventDefault();
        var val = $(this).find('input[type=search]').val();
        if(val){
            searchData(val);
        }
    });

    this.show = function(){
        render = _form;
        $('body').append(render);
        ref = this;
        render.fadeIn(function(){
            document.ScrollContoller.disableScroll();
            if(ref instanceof HTMLInputElement &&  ref.tagName.toLowerCase() == 'input'){
                _form.find('.js-search-ai input[type=search]').val(ref.value);
                ref.value = '';
            }
            _form.find('.js-search-ai input[type=search]').focus();
        });
        $('html').addClass("modal");


    }

    this.hide = function(){
        PartTypes.Selection = [];
        PartTypes.Brands = {};
        PartTypes.PartTypes = {};
        if(_xhr)_xhr.abort();
        if(_timeout) clearTimeout(_timeout);
        _form.find('.input-search-bar button.search .fa').addClass('fa-search').removeClass(' fa-circle-o-notch fa-spin fa-pencil');
        _form.fadeOut(function(){
            _form.find('.c-smart-search--modal.append').remove();
        })
        document.ScrollContoller.enableScroll();
        $('html').removeClass("modal");

    }
    _form.find('button.close').on('click',function(e){
        e.preventDefault();
        this.hide();
    }.bind(this));

    this.search = function($keyword){
        this.show();
        _form.find('.js-search-ai input[type=search]').val($keyword);
        searchData($keyword);
    }
    function searchData($keyword){
        PartTypes.Selection = [];
        PartTypes.Brands = {};
        PartTypes.PartTypes = {};
        _form.find('.c-smart-search--modal.append').remove();
        _form.find('.input-search-bar button.search .fa').addClass('fa-circle-o-notch fa-spin').removeClass('fa-search fa-pencil');
        if(_xhr)_xhr.abort();
        if(_timeout) clearTimeout(_timeout);
        _xhr = $.ajax({
            url:'/ajax/smartbar',
            dataType: 'JSON',
            type: 'POST',
            data: {'keyword': $keyword},
            success: parseData,
            error : function(){},
        })
    }
    function lazyImage(){
        
        $('[data-img]').each(function(){
            if($(this).visible()){
                $(this).after('<img id="'+$(this).attr('id')+'" class="'+$(this).attr('class')+'" src="'+$(this).data('img')+'" />');
                $(this).remove();
            }
        })
    }

    function parseData($data){
        _form.find('.input-search-bar button.search .fa').removeClass('fa-circle-o-notch fa-spin fa-pencil').addClass('fa-search');
        var modal_search = $('<div class="c-smart-search--modal smart-search-result append"><div class="container box"><div class="left-side col-md-3 col-sm-3 col-xs-12 p0"><div class="h2 flex flex--no-wrap flex--align-center p2 mt0"><span class="flex--grow">'+__('Advanced search')+'</span> <button class="tooltip mx1"><i class="fa fa-info-circle"></i><div class="tooltiptext">'+__('tooltip text')+'</div></button> <i class="fa fa-list"></i></div><div class="filters m2"></div></div><div class="right-side p0 col-md-9 col-sm-9 col-xs-12"></div></div></div>');
        
        //Check if empty search
        if($data.brand == null && $data.brands == null && $data.categories == null && $data.parts == null && $data.vehicle.make.ajax != undefined && $data.vehicle.model.ajax != undefined && $data.vehicle.year.length > 1){
            _form.append(modal_search);
            modal_search.find('.left-side').empty();
            var rightSide = modal_search.find('.right-side');
            rightSide.append('<p class="h3">'+__('No result found for "%s"',[_form.find('input[type=search]').val()])+'</p>');
            return;
        }
        
        //SEARCH
        var modal_vehicle = $('<div class="c-smart-search--modal append"></div>');
        modal_vehicle.append(vehicleData($data.vehicle,$data.step2,$data.isNA,$data.token));
        _form.append(modal_vehicle);

        //CATEGORY
        if($data.categories || $data.parts) 
            _form.append(modal_search);
        else{
            modal_vehicle.find('form').submit();
        }

        var leftSide = modal_search.find('.left-side .filters');
        var rightSide = modal_search.find('.right-side');

        if($data.categories){
            var html = categoriesData($data.categories)
            leftSide.append(html);
            var btn = $('<button class="btn" style="width: calc(100% - 20px);">'+__('Go')+'</button>');
            leftSide.append(btn);
            btn.on('click',function(){
                modal_vehicle.find('form').submit();
            });
            /*html.find('.js-chk').on('change',function(){
                modal_vehicle.find('[name=selection]').val(PartTypes.Selection.length?JSON.stringify(PartTypes.Selection):'');
            })*/
        }

        if($data.parts){
            rightSide.append(listParts($data.parts));
        }

        if($data.brand){
            leftSide.prepend(listBrands($data.brand));
            rightSide.append(listBrandsData($data.brand));
        }

        if($data.parttypes){
            var parttypes = listPartType($data.parttypes);
            rightSide.append(parttypes);

            parttypes.on('change',function(){
                modal_vehicle.find('form').submit();
            });
        }

        if($data.brands){
            leftSide.prepend(listBrands($data.brands));
            rightSide.append(listBrandsData($data.brands));
        }

        lazyImage();
    }

    function categoriesData($categories){
        var rsp = $('<div class="container-category"><div class="h3">'+__('All Categories')+'</div><div class="categories"><ul class="js-categories"></ul></div></div>');
        var container = rsp.find('.js-categories');
        var int = 1; 
        for(var key in $categories){
            var categ = new PartTypes.Category(key,int,$categories[key]);
            container.append(categ.getObject());
            int ++;
        }
        return rsp;
    }

    var PartTypes = {
        Selection: [],
        Brands: {},
        PartTypes: {},
        Category : function($name,$index,$children){
            this.name = $name;
            var obj = $('<li class="js-category js-container" data-id="1"><div id="filter-c-submenu-'+$index+'" class="js-submenu submenu-handle"><i class="js-icon fa fa-angle-right" aria-hidden="true"></i> <label class="js-dropdown">'+$name+'</label></div><ul class="js-subcategories subcategories js-level" style="display:none"></ul></li>');
            var container = obj.find('.js-subcategories');
            var icon = obj.find('.js-icon');
            var checkbox = obj.find('input[type=checkbox]');
            obj.find('.js-icon,.js-dropdown').on('click', function(){
                container.toggle();
                icon.toggleClass('fa-angle-right fa-angle-down');
            })
            checkbox.on('change',function(){
                if(checkbox.is(':checked'))
                    obj.find('.js-chk').prop('indeterminate',false).prop('checked',true).trigger('change');
                else 
                    obj.find('.js-chk').prop('indeterminate',false).prop('checked',false).trigger('change');
            });
            var int = 1;
            for(var key in $children){
                var sub = new PartTypes.SubCateg(key,$index+'-'+int,$children[key],this);
                container.append(sub.getObject());
                int ++;
                sub.addEvent(function(){
                    var checked = container.find('[type=checkbox]:checked').length;
                    var tot = container.find('[type=checkbox]').length;
                    if(checked == tot)
                        checkbox.prop('indeterminate',false).prop('checked',true);
                    else if(checked>0)
                        checkbox.prop('indeterminate',true).prop('checked',false);
                    else
                        checkbox.prop('indeterminate',false).prop('checked',false);
    
                    checkbox.trigger('update');
                })
            }
            this.getObject = function(){
                return obj;
            }
            this.addEvent = function(calback){
                checkbox.on('update',calback);
            }
        },
        SubCateg : function($name,$index,$children,$parent){
            this.name = $name;
            this.parent = $parent;
            var obj = $('<li class="js-subcategory js-container"><div class="js-submenu submenu-handle" id="filter-sc-submenu-'+$index+'" data-target=".js-parttypes"><i class="js-icon fa fa-angle-right" aria-hidden="true"></i> <input type="checkbox" class=" input-hidden" id="ai-filter-sc-'+$index+'" data-type="subcategory" value="'+$index.split('-').pop()+'"> <label class="input-checkbox--fake" for="ai-filter-sc-'+$index+'"></label><label class="js-dropdown">'+$name+'</label></div><ul class="js-parttypes parttypes js-level" style="display:none"></ul></li>');
            var container = obj.find('.js-parttypes');
            var icon = obj.find('.js-icon');
            var checkbox = obj.find('input[type=checkbox]');
            obj.find('.js-icon,.js-dropdown').on('click', function(){
                container.toggle();
                icon.toggleClass('fa-angle-right fa-angle-down');
            })
            checkbox.on('change',function(){
                if(checkbox.is(':checked'))
                    obj.find('.js-chk').prop('indeterminate',false).prop('checked',true).trigger('change');
                else 
                    obj.find('.js-chk').prop('indeterminate',false).prop('checked',false).trigger('change');
            });
            var int = 1;
            for(var key in $children){
                var sub = new PartTypes.PartType(key,$index+'-'+int,$children[key],this);
                container.append(sub.getObject());
                int ++;
                sub.addEvent(function(){
                    var checked = container.find('[type=checkbox]:checked').length;
                    var tot = container.find('[type=checkbox]').length;
                    if(checked == tot)
                        checkbox.prop('indeterminate',false).prop('checked',true);
                    else if(checked>0)
                        checkbox.prop('indeterminate',true).prop('checked',false);
                    else
                        checkbox.prop('indeterminate',false).prop('checked',false);
    
                    checkbox.trigger('update');
                })
            }
            this.getObject = function(){
                return obj;
            }
            this.addEvent = function(calback){
                checkbox.on('update',calback);
            }
        },
        PartType : function($name,$index,$children,$parent){
            this.name = $name;
            this.parent = $parent;
            var obj = $('<li class="js-parttype js-container"><div class="js-submenu submenu-handle" id="filter-pt-submenu-'+$index+'" data-target=".js-manufacturers"><input type="checkbox" class=" input-hidden" id="ai-filter-pt-'+$index+'" data-type="parttype" value="'+$index.split('-').pop()+'"> <label class="input-checkbox--fake" for="ai-filter-pt-'+$index+'"></label><label for="ai-filter-pt-'+$index+'">'+$name+'</label></div><ul class="js-manufacturers manufacturers js-level" style="display:none"></ul></li>');
            var container = obj.find('.js-manufacturers');
            var checkbox = obj.find('input[type=checkbox]');
            if(!PartTypes.PartTypes[$name]){
                PartTypes.PartTypes[$name] = [];
            }
            PartTypes.PartTypes[$name].push(this);
            checkbox.on('change',function(){
                if(checkbox.is(':checked'))
                    obj.find('.js-chk').prop('indeterminate',false).prop('checked',true).trigger('change');
                else 
                    obj.find('.js-chk').prop('indeterminate',false).prop('checked',false).trigger('change');
            });
            var int = 1;
            for(var key in $children){
                var sub = new PartTypes.Brand($index+'-'+int,$children[key],this);
                container.append(sub.getObject());
                sub.addEvent(function(){
                    var checked = container.find('[type=checkbox]:checked').length;
                    var tot = container.find('[type=checkbox]').length;
                    if(checked == tot)
                        checkbox.prop('indeterminate',false).prop('checked',true);
                    else if(checked>0)
                        checkbox.prop('indeterminate',true).prop('checked',false);
                    else
                        checkbox.prop('indeterminate',false).prop('checked',false);
    
                    checkbox.trigger('update');
                })
                int ++;
            }
            this.getObject = function(){
                return obj;
            }
            this.addEvent = function(calback){
                checkbox.on('update',calback);
            }
        },
        Brand : function($index,$children,$parent){
            var obj = $('<li class="js-manufacturer js-container manufacturer"><input type="checkbox" class="js-chk input-hidden" data-isna="'+($children.isNA?1:0)+'" id="manufacturer-'+$index+'" data-type="manufacturer" value="'+$children.name+'"> <label for="manufacturer-'+$index+'" class="input-checkbox--fake"></label><label for="manufacturer-'+$index+'">'+$children.name+'</label></li>');
            var checkbox = obj.find('input[type=checkbox]');
            if(!PartTypes.Brands[$children.name]){
                PartTypes.Brands[$children.name] = [];
            }
            PartTypes.Brands[$children.name].push(this);
            this.getObject = function(){
                return obj;
            }
            this.addEvent = function(calback){
                checkbox.on('change',calback);
            }
            var data = []
            if($children.isNA){
                data.push({
                    "Manufacturer":$children.name,
                    "PartTerminology":$parent.name,
                    "SubCategory":$parent.parent.name,
                    "Category":$parent.parent.parent.name,
                    "isNA":true,
                });
            }
            if($children.isA){
                data.push({
                    "Manufacturer":$children.name,
                    "PartTerminology":$parent.name,
                    "SubCategory":$parent.parent.name,
                    "Category":$parent.parent.parent.name,
                    "isNA":false,
                });
            }

            this.addEvent(function(){
                data.forEach(function(el){
                    var index = PartTypes.Selection.indexOf(el);
                    if (index > -1) {
                        PartTypes.Selection.splice(index, 1);
                    }
                    if(checkbox.is(':checked'))
                        PartTypes.Selection.push(el);
                });
            })
        }
    }

    function vehicleData($vehicle, $url, $urlNA, $token){
        var container = $('<form class="vehicule-definition" method="POST"><input type="hidden" name="token" value="'+$token+'" /><textarea name="selection"></textarea></form>');
        var selection = container.find('[name=selection]')
        container.on('submit',function(e){
            e.preventDefault();

            var dataApp = [];
            var dataNA = [];
            var url = $urlNA;

            PartTypes.Selection.forEach(function(el){
                if(el.isNA) dataNA.push(el);
                else dataApp.push(el);
            });
            
            if(!dataApp.length && dataNA.length){
                selection.val(dataNA.length?JSON.stringify(dataNA):'');
            }
            else if(year && make && model){
                selection.val(dataApp.length?JSON.stringify(dataApp):'');
                url = $url.replace('$1', encodeURI(year.val())).replace('$2', encodeURI(make.val())).replace('$3', encodeURI(model.val()));

                if(!year.val()){
                    year.open();
                    return false;
                }
                else if(!make.val()){
                    make.open();
                    return false;
                }
                else if(!model.val()){
                    model.open();
                    return false;
                }
            }
            if(selection.val()){
                container.attr('action',url).off('submit').submit();
            }
            else
                window.location.href = url;
        })
        if($vehicle.year){
            var year = new vehicleFieldData($vehicle.year,'js-year year',__('Year'));
            container.append(year.getObject());
            year.addEvent(function(){
                if(!make.update({year:year.val()}))
                    model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.make){
            var make = new vehicleFieldData($vehicle.make,'js-make make',__('Make'));
            container.append(make.getObject());
            make.addEvent(function(){
                model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.model){
            var model = new vehicleFieldData($vehicle.model,'js-model model',__('Model'));
            container.append(model.getObject());
            model.addEvent(function(){
                container.submit();
            });
        }
        return container;
    }

    function vehicleFieldData($field, $class,$text){
        var render = false;
        if(!isNaN($field) || typeof $field == 'string'){
            render = $('<div class="field-group"><input type="text" class="'+$class+'" value="'+$field+'" readonly ></div>');
        }
        else if(Array.isArray($field)){
            render = $('<div class="field-group selectbox "><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            $field.forEach(function(el){
                render.find('select').append('<option class="js-label" value="'+el+'">'+el+'</option>')
            });
            if (!Tools.IsMobile())
                render.find('select').select2();
        }
        else if($field.ajax){
            render = $('<div class="field-group selectbox"><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            if (!Tools.IsMobile()) 
                render.find('select').select2();
        }

        this.getObject = function(){
            return render;
        }
        this.addEvent = function(calback){
            return render.find('input,select').on('change',calback);
        }
        this.val = function(){
            return render.find('input,select').val();
        }

        this.open = function(){
            var target = render.find('select');
            if(!target.val()){
                if (!Tools.IsMobile() && target.hasClass('select2-hidden-accessible')) {
                    target.select2("close");
                    target.select2("open");
                } else {
                    target.simulate("mousedown");
                }
            }
        }

        this.update = function($data){
            if($field.ajax){
                target = render.find('select')
                $.ajax({
                    url:$field.ajax,
                    dataType: 'JSON',
                    type: 'POST',
                    data: $data,
                    success: function(data){
                        var html = '<option class="js-label" value="">' + $text + '</option>';
                        if (!data.error)
                        {
                            for (var i = 0; i < data.values.length; i++)
                            {
                                html += '<option value="' + data.values[i] + '">' + data.values[i] + '</option>';
                            }
                        }
                        target.html(html);
                        this.open();
                    }.bind(this)
                });
                return true;
            }
            return false;
        }
    }

    function listBrands($brands){
        var rsp = $('<div class="container-brands brands p0"><div class="h3">'+__('All Brands')+'</div><div class="manufacturers container p0"><ul></ul></div></div>')
        var container = rsp.find('ul');
        $brands.forEach(function(el){
            if(!PartTypes.Brands[el.name]) return;
            var id = 'sc-manufacturer-' + el.title;

            var brand = $('<li><label id="filter-'+id+'" for="'+id+'" class="submenu-handle"><input id="'+id+'" type="checkbox" class="input-hidden" /> '+el.name+'</label></li>')
            var checkbox = brand.find('input')
            container.append(brand);
            if(PartTypes.Brands[el.name]){
                PartTypes.Brands[el.name].forEach(function($brand){
                    $brand.addEvent(function(){
                        var tot = 0;
                        var checked = 0;
                        PartTypes.Brands[el.name].forEach(function($brand){
                            checked += $brand.getObject().find('.js-chk:checked').length;
                            tot += $brand.getObject().find('.js-chk').length;
                        });
                        if(checked == tot)
                            checkbox.prop('indeterminate',false).prop('checked',true);
                        else if(checked>0)
                            checkbox.prop('indeterminate',true).prop('checked',false);
                        else
                            checkbox.prop('indeterminate',false).prop('checked',false);
                    })
                });
                checkbox.on('change',function(){
                    var state = checkbox.is(':checked');
                    PartTypes.Brands[el.name].forEach(function($brand){
                        $brand.getObject().find('.js-chk').prop('indeterminate',false).prop('checked',state).trigger('change');
                    });
                });
            }
        });
        return rsp;
    }

    function listBrandsData($brands){
        var rsp = $('<div class="container-brands brands p0"><div class="h3">'+__('Brands')+'</div><div class="manufacturers container p0 mb1"></div></div>')
        var container = rsp.find('.manufacturers');
        $brands.forEach(function(el){
            var brand = $('<div class="col-md-3 col-sm-3 col-xs-6" data-category="'+el.title.charAt(0)+'"><div class="container-brand manufacturer"><a href="'+el.url+'" class="brand-item  js-brand-item" title="'+el.title+'"><span data-img="'+el.logo+'" alt="'+el.title+'"></span></a></div></div>')
            container.append(brand);
        });
        return rsp;
    }

    function listPartType($partTypes){
        var rsp = $('<div class="container-part p0"><div class="h3">'+__('Part types')+'</div><ul class="parttypes flex container p0"></ul></div>');
        var container = rsp.find('.parttypes');

        $partTypes.forEach(function(el){
            var image = (el.partTypeImage?el.partTypeImage:'/dist/images/no-image.png');
            if(!is_image(image)) image = '/dist/images/image-broken.png';
            var pt = $('<li class="container-parttype col-md-4 col-sm-6 col-xs-12"><label class="smart-search-product" ><span class="container-img"><span data-img="'+image+'" data-origin="'+el.partTypeImage+'" class="brand-part-type-image"></span></span><span>'+el.partTypeName+'</span></label><li>')
            var label = pt.find('label');
            container.append(pt);
            if(PartTypes.PartTypes[el.partTypeName]){
                PartTypes.PartTypes[el.partTypeName].forEach(function($brand){
                    $brand.addEvent(function(){
                        var tot = 0;
                        var checked = 0;
                        PartTypes.PartTypes[el.partTypeName].forEach(function($brand){
                            checked += $brand.getObject().find('.js-chk:checked').length;
                            tot += $brand.getObject().find('.js-chk').length;
                        });
                        if(checked>0){
                            label.addClass('active');
                        }
                        else
                            label.removeClass('active');
                    })
                });
                label.on('click',function(){
                    var state = true;
                    PartTypes.PartTypes[el.partTypeName].forEach(function($brand){
                        $brand.getObject().find('.js-chk').prop('indeterminate',false).prop('checked',state).trigger('change');
                    });
                    rsp.trigger('change');
                });
            }
        });
        if(typeof lazyImage == 'function')
            lazyImage();

        return rsp;
    }

    function listParts($partTypes){
        var rsp = $('<div class="container-part p0"><div class="h3">'+__('Parts')+'</div><ul class="parttypes flex container p0"></ul></div>');
        var container = rsp.find('.parttypes');

        $partTypes.forEach(function(el){
            icon = el.partUrls?el.partUrls[0]:'/dist/images/no-image.png';
            var pt = $('<li class="container-parttype col-md-4 col-sm-6 col-xs-12"><a href="'+el.url+'" class="smart-search-product" ><span class="container-img"><span data-img="'+icon+'" class="brand-part-type-image"></span></span><span>'+el.partType+'<br>'+el.manufacturerName+'<br>'+(el.companyInfos[0].netPrice?'<b>'+(el.companyInfos[0].discountPrice && el.companyInfos[0].discountPrice<el.companyInfos[0].netPrice?el.companyInfos[0].discountPrice:el.companyInfos[0].netPrice).toFixed(2)+'$</b>':'')+'</span></a>'+(el.companyInfos[0].netPrice && el.companyInfos[0].stockLeft > 0?'<div class="cart-controller"><button class="btn js-btn-addtocart">'+__('Add to cart')+'</button></div>':'')+'</li>');
            container.append(pt);
            pt.on('click', '.js-btn-addtocart', function(e){    
                e.preventDefault();
                button = $(this);
                button.attr('disabled',true);
                button.prepend('<i class="fa fa-circle-o-notch fa-spin"></i> ')

                
                $.ajax({
                    url:'/ajax/addtocart/',
                    dataType: 'JSON',
                    type: 'POST',
                    data: {
                        quantity : 1,
                        productcode : el.partNumber,
                        linecode : el.supplierCode,
                        year : null,
                        make : null,
                        model : null,
                        manufacturer : el.manufacturerName,
                        description : el.partType,
                        parttype : el.partType
                    },
                    success: function(data){
                        if (data.error) {
                        }  else if (data.count > 0) {
                            button.after('<a class="btn btn--inverse" href="/cart/">'+__('View cart')+'</a>');
                            button.remove();
                            jQuery('.js-menu-cart-count').html('(' + data.count + ')').parent().find('.fa').addClass('fa-red');
                        }

                        jQuery('.js-menu-cart-count').html('(' + data.count + ')').parent().find('.fa').addClass('fa-red');
                    },
                    error: function(){
                        button.attr('disabled',false);
                        button.find('.fa-circle-o-notch').remove();
                    }
                });
            });
        });

        if(typeof lazyImage == 'function')
            lazyImage();

        return rsp;
    }

    function is_image(src){
        var support = ['JPG','JPEG','PNG','GIF','BMP','SVG','WEBP','TIF'];
        var test = src.split('.').pop().trim().toUpperCase();
        while(support.length)
            if(support.pop() == test)
                return true;
        return false;
    }
}


jQuery('document').ready(function(){
    $('.js-search-ai input').focusin(SmartBar.show);
    $('.js-search-ai-button').on('click',function(e){e.preventDefault(); SmartBar.show()});
    if($('.js-search-ai input').val()){
        SmartBar.search($('.js-search-ai input').val());
    }

    /*$('.js-search-ai').submit(function(e){
        e.preventDefault();
        SmartBar.search( $(this).find('input[type=search]').val() );
    })*/

    //SmartBar.search('Prius 2015 Pad brake Bosch');
});