if(window.VehicleSearch == undefined) window.VehicleSearch = {};
VehicleSearch.Popup = function(){
    if(!(this instanceof VehicleSearch.Popup))
        return new VehicleSearch.Popup();    
    var _form = $('<div class="c-smart-search" style="display:none"></div>')
    var _xhr = null;
    var _timeout=null;

    this.show = function(parttype,url){
        render = _form;
        $('body').append(render);
        ref = this;
        render.fadeIn(function(){});
        document.ScrollContoller.disableScroll();
        _form.append(createHeader(parttype))
        _form.append(getVehicleSearch(function($ymm){
            createLoader()
            window.location.href = url.replace('$1',$ymm.year).replace('$2',$ymm.make).replace('$3',$ymm.model)
        }))
    }

    this.hide = function(){
        if(_xhr)_xhr.abort();
        if(_timeout) clearTimeout(_timeout);
        _form.find('.input-search-bar button.search .fa').addClass('fa-search').removeClass(' fa-circle-o-notch fa-spin fa-pencil');
        _form.fadeOut(function(){
            _form.find('.c-smart-search--modal.append').remove();
            _form.find('.c-smart-search--title').remove();
        })
        document.ScrollContoller.enableScroll();
    }

    _form.on('click','button.close',function(e){
        e.preventDefault();
        this.hide();
    }.bind(this));

    function createHeader(title){
        var header = $('<div class="c-smart-search--title">'+Translator.trans('Select your vehicle')+'</div>');
        header.append(buttonClose());
        return header;
    }

    function buttonClose(){
        var btn = $('<button class="close"><i class="fa fa-close"></i></button>');
        return btn;
    }

    function createLoader(){
        try{
            return new LoadingScreen();
        }
        catch(e){}
    }

    function getVehicleSearch($callback){
        var modal_vehicle = $('<div class="c-smart-search--modal append"></div>');

        console.log('HERE');

        callAPI('/ajax/yearsbymodel',{}).then(function(data){
            var t = {
                "year":data.values,
                "make":{"ajax":"/ajax/makesbyyear/"},
                "model":{"ajax":"/ajax/modelsbymake/"}
            }
            modal_vehicle.append(vehicleData(t,$callback))
        })
        return modal_vehicle;
    }


    function callAPI(uri,data){
        var loader = createLoader();
        return new Promise(function(resolve, reject) {
            var request;
            if (window.ActiveXObject)
                request = new ActiveXObject('Microsoft.XMLHTTP');
            else
                request = new XMLHttpRequest();
            request.open("POST",uri);
            request.onload = function() {
                if(loader != undefined) loader.remove();
                try{ resolve(JSON.parse(request.responseText)); }
                catch(ex){ reject(ex) }
            };
            request.send(getFormData(data));
        });
    }

    function getFormData(data){
        var FD = new FormData();
        for(var key in data) FD.append(key,data[key]);
        return FD;
    }

    // Year make model
    function vehicleData($vehicle,$callback){
        var container = $('<form class="vehicule-definition" method="POST"></form>');
        var selection = container.find('[name=selection]')
        container.on('submit',function(e){
            e.preventDefault();
            if(!year.val()){
                year.open();
                return false;
            }
            else if(!make.val()){
                make.open();
                return false;
            }
            else if(!model.val()){
                model.open();
                return false;
            }
            if(typeof $callback == 'function'){
                $callback({year: year.val(), make: make.val(), model: model.val()});
            }
        })
        if($vehicle.year){
            var year = new vehicleFieldData($vehicle.year,'js-year year',__('Year'));
            container.append(year.getObject());
            year.addEvent(function(){
                if(!make.update({year:year.val()}))
                    model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.make){
            var make = new vehicleFieldData($vehicle.make,'js-make make',__('Make'));
            container.append(make.getObject());
            make.addEvent(function(){
                model.update({year:year.val(), make:make.val()});
                container.submit();
            });
        }
        if($vehicle.model){
            var model = new vehicleFieldData($vehicle.model,'js-model model',__('Model'));
            container.append(model.getObject());
            model.addEvent(function(){
                container.submit();
            });
        }
        return container;
    }

    function vehicleFieldData($field, $class,$text){
        var render = false;
        if(!isNaN($field) || typeof $field == 'string'){
            render = $('<div class="field-group"><input type="text" class="'+$class+'" value="'+$field+'" readonly ></div>');
        }
        else if(Array.isArray($field)){
            render = $('<div class="field-group selectbox "><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            $field.forEach(function(el){
                render.find('select').append('<option class="js-label" value="'+el+'">'+el+'</option>')
            });
            render.find('select').select2();
        }
        else if($field.ajax){
            render = $('<div class="field-group selectbox"><select class="'+$class+'" tabindex="-1" aria-hidden="true"><option value="">'+$text+'</option></select></div>');
            render.find('select').select2();
        }

        this.getObject = function(){
            return render;
        }
        this.addEvent = function(calback){
            return render.find('input,select').on('change',calback);
        }
        this.val = function(){
            return render.find('input,select').val();
        }

        this.open = function(){
            var target = render.find('select');
            if(!target.val()){
                target.select2("close");
                target.select2("open");
            }
        }

        this.update = function($data){
            if($field.ajax){
                target = render.find('select')
                target.attr('disabled',true);
                callAPI($field.ajax, $data).then(function($rsp){
                    $key = $text.toLowerCase();
                    var html = '<option class="js-label" value="">' + $text + '</option>';
                    for($key in $rsp.values)
                    {
                        var label = $rsp.values[$key]
                        html += '<option value="' + label + '">' + label + '</option>';
                    }
                    target.html(html);
                    target.attr('disabled',false);
                    if(target.find('option').length>1){
                        this.open();
                    }
                }.bind(this))
                return true;
            }
            return false;
        }
    }
}