if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.Transaction = function(ID,affiliation,items,taxes){
    if(items && !(items instanceof ActivityTracker.ItemsCollection))
        throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
    this.getId = function(){
        return ID
    }
    this.getAffiliation = function(){
        return affiliation
    }
    this.getItems = function(){
        return items
    }
    this.getSubTotal = function(){
        return items.Sum('total')
    }
    this.getCurrency = function(){
        if(items)
            return items.firstOrDefault().getCurrency();
        return '';
    }
    this.getTaxes = function(){
        return parseFloat(taxes)
    }
    this.getShippingFee = function(){
        return 0
    }

    this.getTotal = function(){
        return this.getSubTotal() + this.getTaxes();
    }
};