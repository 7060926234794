if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.Vehicle = function(year,make,model){
    if(!(this instanceof ActivityTracker.Vehicle))
        throw new Error("Uncaught TypeError: ActivityTracker.Vehicle is a construtor.");

    this.getYear = function(){
        return parseInt(year);
    }

    this.getMake = function(){
        return make;
    }

    this.getModel = function(){
        return model;
    }

    this.getName = function(){
        if(make && model && year) return make + ' ' + model + ' - '+ year;
        return "General Use";
    }
};
