if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.Sender = new function(){
    var implements

    function __construct(method,params){
        implements = [
            new ActivityTracker.SenderGoogleTag(),
            new ActivityTracker.SenderFacebook('CAD'),
        ]
    }

    function calls(method,params){
        for(k in implements){
            implements[k][method](params);
        }
    }

    this.push = function(eventName){
        calls('push',eventName);
    }

    this.viewProducts = function(items){
        if(!(items instanceof ActivityTracker.ItemsCollection))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('viewProducts',items);
    }

    this.viewProduct = function(item){
        if(!(item instanceof ActivityTracker.Item))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('viewProduct',item);
    }

    this.addToCart = function(item){
        if(!(item instanceof ActivityTracker.Item))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('addToCart',item);
    }

    this.removeToCart = function(items){
        if(!(items instanceof ActivityTracker.Item))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('removeToCart',items);
    }

    this.viewCart = function(items){
        if(!(items instanceof ActivityTracker.ItemsCollection))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('viewCart',items);
    }

    this.beginCheckout = function(items){
        if(!(items instanceof ActivityTracker.ItemsCollection))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('beginCheckout',items);
    }

    this.addPaymentInfo = function(items){
        if(!(items instanceof ActivityTracker.ItemsCollection))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.ItemsCollection");
        calls('addPaymentInfo',items);
    }

    this.purchase = function(transaction){
        if(!(transaction instanceof ActivityTracker.Transaction))
            throw new Error("Uncaught TypeError: The parameter items is not an instance of ActivityTracker.Transaction");
        calls('purchase',transaction);
    }
    document.addEventListener("DOMContentLoaded", __construct);
};

function TextToActivityTrackerEvent(action,data_text){
    var data = JSON.parse(data_text);
    if(data.referencesOrder){
        collection = JSONParseToItemsCollection(data);
        ActivityTracker.Sender[action](collection);
    }
}

function JSONParseToItemsCollection(data){
    var collection = new ActivityTracker.ItemsCollection();
    for (var key in data.referencesOrder) {
        var data_item = data.referencesOrder[key];
        var vehicle = new ActivityTracker.Vehicle();
        var decoded = data_item.description.split(' | ');
        if(decoded.length == 3){
            vehicle = new ActivityTracker.Vehicle(decoded[2],decoded[0],decoded[1]);
        }
        var item = new ActivityTracker.Item('','',
            data_item.orderedItem.orderedItem.name,
            data_item.orderedItem.orderedItem.brand.name,
            data_item.orderedItem.orderedItem.productID,
            data_item.orderedItem.orderedItem.offers.price,
            data_item.orderedItem.orderQuantity,
            vehicle
        )
        item.setAffiliation(data_item.orderedItem.orderedItem.offers.seller.name);
        item.transaction = data_item.orderNumber
        collection.add(item)
    }
    return collection;
}

function TextToActivityTrackerPurchase(data_text){
    var data = JSON.parse(data_text);
    if(data.referencesOrder){
        var collection = JSONParseToItemsCollection(data);
        split = collection.groupBy(['transaction']);

        split.forEach(function(t){
            try{
                var transaction = new ActivityTracker.Transaction(
                    t.group.transaction, 
                    t.collection.firstOrDefault().getAffiliation(),
                    t.collection,
                    data.totalPaymentDue.taxes.price
                )
                ActivityTracker.Sender.purchase(transaction);
            }catch (e){console.log(e);}
        });
    }
}
