if(window.ActivityTracker == undefined) window.ActivityTracker = {};
ActivityTracker.ItemsCollection = function(){
    if(!(this instanceof ActivityTracker.ItemsCollection))
      return new ActivityTracker.ItemsCollection(raw_data)

    var _items = [];
    
    this.where = function(properties){
        var searchProperties = GetGetterFromSearch(properties);
        var collection = new ActivityTracker.ItemsCollection();
        _items.forEach(function(item){
            if(MatchSearch(item,searchProperties))
            {
                collection.add(item);
            }
        })
        return collection;
    }

    this.find = function(properties){
        var searchProperties = GetGetterFromSearch(properties);
        for(var key in _items){
            if(MatchSearch( _items[key],searchProperties))
                return _items[key]
        }
        throw new Exception('Item not found whit criteria.');
    }

    this.orderBy = function(properties,orderProperties){
        var orderProperties = GetGetterFromSearch(properties);
        _items.sort(function(a,b){
            for(k in orderProperties){
                var order = orderProperties[k].value(GetProperty(a,orderProperties[k]), GetProperty(b,orderProperties[k]));
                if(order!=0) return order;
            }
            return 0;
        });
        return this;
    }

    this.forEach = function(callback){
        for(k in _items){
            callback(_items[k]);
        }
    }

    this.add = function(store){
        _items.push(store);
        return this;
    }

    this.groupBy = function(properties){
        var groups = [];
        for(var key in _items){
            var found = false;
            for(var index in groups){
                var searchProperties = GetGetterFromSearch(groups[index].group);
                if(MatchSearch(_items[key],searchProperties)){
                    found = groups[index];
                    break;
                }
            }
            if(!found){
                var found = {
                    group: {},
                    collection: new ActivityTracker.ItemsCollection(),
                }
                for(var index in properties){
                    found.group[properties[index]] = GetProperty(_items[key],GetGetterObj(properties[index]));
                }
                groups.push(found);
            }
            found.collection.add(_items[key])
        }
        return groups;
    }

    this.firstOrDefault = function(){
        for(k in _items){
            return _items[k];
        }
        return false;
    }

    this.Count = function(){
       return  _items.length;
    }

    this.Sum = function(property){
        var sum = 0;
        for(var k in _items){
            try{

                sum += parseFloat(GetProperty(_items[k],GetGetterObj(property)))
            } catch(Exception){}
        }
        return sum;
        
    }

    function MatchSearch(item,properties){
        for(k in properties){
            if(item[properties[k].get] ==undefined && item[properties[k].property] == undefined) 
                throw new Error("The object list does not contain the property :" +  properties[k].property)
            else if(GetProperty(item,properties[k]) != properties[k].value)
                return false
        }
        return true;
    }

    function GetProperty(item, getter){
        if(item[getter.get] !=undefined) 
            return item[getter.get]();
        return item[getter.property]
    }

    function GetGetterFromSearch(properties){
        var getters = []
        for(k in properties){
            getters.push(GetGetterObj(k,properties[k]));
        }
        return getters;
    }

    function GetGetterObj(key, value){
        return {
            property: key,
            get: toGetterNameFunction(key),
            value: value
        }
    }

    function toGetterNameFunction(propertyName){
        propertyName = propertyName.replace(/_(\S)/g, function(v) { return v.replace('_','').toUpperCase(); });
        return 'get'+ propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
    }
};